<template>
  <div class="row">
    <div class="col-12">
      <!-- 평가대상 목록 - 유해위험요인 -->
      <c-table
        ref="table"
        title="LBL0001108"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="process.scenarios"
        :merge="grid.merge"
        :columnSetting="false"
        :expandAll="false"
        :isFullScreen="false"
        :usePaging="false"
        :hideBottom="true"
        :filtering="false"
        :editable="editable&&!disabled&&isSelect&&!assessTypeRoutineCheck"
        selection="multiple"
        rowKey="ramKrasAssessScenarioId"
        @innerBtnClicked="innerBtnClicked"
      >
        <!-- @rowClick="rowClick" -->
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-chip 
            outline square clickable 
            size="md"
            color="light-green-8" 
            text-color="white"
            icon="contact_support"
            label="범례"
            style="height:26px;margin-right:2px !important;"
            class="q-ml-sm">
            <q-popup-proxy>
              <q-banner>
              <q-chip color="teal" outline square/>
                <!-- 유해위험요인추가 -->
                {{$label('LBL0001099')}}
              </q-banner>
            </q-popup-proxy>
          </q-chip>
          <q-btn-group outline >
            <c-btn v-show="editable&&!disabled&&isSelect&&!assessTypeRoutineCheck" label="LBLADDDIRECTLY" icon="add" @btnClicked="addJob" />
            <!-- SOP 추가 -->
            <c-btn v-show="editable&&!disabled&&isSelect&&!assessTypeRoutineCheck" label="LBL0001100" icon="add" @btnClicked="openSop" />
            <c-btn v-show="editable&&!disabled&&isSelect&&!assessTypeRoutineCheck" label="LBLREMOVE" icon="remove" @btnClicked="removeSop" />
            <c-btn 
              v-show="false" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="process.scenarios"
              mappingType="PUT"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveSop"
              @btnCallback="saveSopCallback" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <q-chip
              color="amber"
              outline square
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click.stop="() => { rowClick(props.row, props.pageIndex) }">
              <!-- 수정 -->
              {{$label('LBLUPDATE')}}
            </q-chip>
          </template>
          <template v-else-if="col.name==='picture'">
            <q-btn
              class="tableinnerBtn"
              flat
              align="center"
              color="blue-6"
              label=""
              @click.stop="openPicture(props, col)">
              <template v-slot:default >
                <template v-if="props.row.editFlag!=='C'">
                  <q-btn 
                    unelevated round dense
                    size="10px"
                    color="primary" 
                    icon="wallpaper" />
                </template>
              </template>
            </q-btn>
          </template>
        </template>
      </c-table>
    </div>
    <q-dialog v-model="sopDialog" persistent>
      <q-card style="min-width: 350px">
        <q-form ref="editForm">
          <!-- 유해위험요인 추가 -->
          <c-card title="LBL0001099" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  v-show="editable&&!disabled&&isSelect&&isRowEditing&&!assessTypeRoutineCheck" 
                  :disabled="disabledSop" 
                  label="LBLSAVE" 
                  icon="save" 
                  @btnClicked="accepts"/>
                <c-btn 
                  label='LBLCLOSE' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="LBLPROCESS"
                  name="processName"
                  v-model="sop.processName">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledSop || !isRowClick || Boolean(sop.mdmSopId) || assessTypeRoutineCheck"
                  :required="true"
                  label="LBLJOB"
                  name="jobName"
                  v-model="sop.jobName"
                  @dataChange="jobChange">
                </c-text>
              </div>
              <div class="col-12">
                <!-- 유해위험요인 -->
                <c-text
                  :afterIcon="editable&&!disabledSop&&isRowEditing&&!assessTypeRoutineCheck ? [
                    { name: 'search', click: true, callbackName: 'searchRiskHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeRiskHazard', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :disabled="disabledSop||assessTypeRoutineCheck"
                  :required="true"
                  :close="false"
                  label="LBL0001050"
                  name="riskHazardName"
                  v-model="sop.riskHazardName"
                  @searchRiskHazard="searchRiskHazard"
                  @removeRiskHazard="removeRiskHazard"
                  @dataChange="chagneRiskHazard">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabledSop||assessTypeRoutineCheck"
                  :afterIcon="editable&&!disabledSop&&isRowEditing&&!assessTypeRoutineCheck ? [
                    { name: 'search', click: true, callbackName: 'searchCause', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeCause', color: 'red' }
                  ] : null"
                  :close="false"
                  label="상황/원인"
                  name="riskOccurrenceCause"
                  @searchCause="searchCause"
                  @removeCause="removeCause"
                  v-model="sop.riskOccurrenceCause">
                </c-text>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'kras-target-process',

  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        equipments: [],
        scenarios: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
        research: '',
      }),
    },
    assessTypeRoutineCheck: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'groupMdmSopId' },
          { index: 1, colName: 'groupRiskHazard' },
          { index: 2, colName: 'groupRiskHazard' },
        ],
        columns: [
          {
            name: 'jobName',
            field: 'jobName',
            label: '작업순서',
            align: 'left',
            style: 'width:150px',
            sortable: true,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'teal' }
            ]
          },
          {
            name: 'riskHazardNameClass',
            field: 'riskHazardNameClass',
            // 분류 - 유해위험요인
            label: 'LBL0001105',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'picture',
            field: 'picture',
            // 유해위험요인<br/>사진
            label: 'LBL0001109',
            align: 'center',
            type: 'custom',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            label: '상황/원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLDETAIL',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '550px'
      },
      sop: {
        ramKrasAssessScenarioId: '',  // 안전작업 일련번호
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        mdmSopId: '',  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
        jobName: '',  // 안전작업 명
        ramRiskHazardClassCd: '', // 유해위험요인 분류 코드
        ramRiskHazardId: '',  // 유해위험요인 코드
        riskHazardName: '',  // 유해위험요인 명
        riskHazardNameClass: '',  // 유해위험요인 명
        relationLaw: '',  // 관련근거(법적기준)
        riskOccurrenceCause: '',
        riskOccurrenceResult: '',  // 위험발생 결과
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      isSameJobName: false,
      isRowClick: false,
      isRowAdd: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      rowIndex: 0,
      sopDialog: false,
    };
  },
  computed: {
    isSelect() {
      return this.process 
        && this.process.processCd
        && this.process.ramProcessAssessStepCd !== 'RPA0000005' ? true : false
    },
    disabledSop() {
      return this.disabled || !this.isSelect
    },
    isRowEditing() {
      return this.isRowClick || this.isRowAdd
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.kras.scenario.list.url
      this.saveUrl = transactionConfig.ram.kras.scenario.save.url
      this.deleteUrl = transactionConfig.ram.kras.scenario.delete.url
      // code setting
      // list setting
    },
    getSops() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd
      }
      this.$http.request((_result) => {
        this.process.scenarios = _result.data
      },);
    },
    chagneRiskHazard(props) {
      /**
       * 직적 입력하는 순간 유해위험요인의 id는 null처리
       */
      if (props && props.row) {
        this.$set(props.row, 'ramRiskHazardId', null)
        this.$set(props.row, 'riskHazardNameClass', null)
        this.$set(props.row, 'ramRiskHazardClassCd', null)
        this.$set(props.row, 'relationLaw', null)
      } else {
        this.sop.ramRiskHazardId = null
        this.sop.riskHazardNameClass = null
        this.sop.ramRiskHazardClassCd = null
        this.sop.relationLaw = null
      }
    },
    openSop() {
      this.popupOptions.title = 'SOP 검색'; // SOP 검색
      this.popupOptions.param = {
        type: 'multiple',
        // searchType: '7', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
        subProcessCd: this.process.processCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.process.scenarios, { ramRiskHazardId: item.mdmHazardFactorsId, }) === -1) {
            this.process.scenarios.splice(0, 0, {
              ramKrasAssessScenarioId: uid(),
              ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
              processCd: this.process.processCd,
              processName: this.process.processName,
              ramTechniqueCd: 'RT00000020',
              groupMdmSopId: this.process.processCd + '/' + item.mdmSopId,
              mdmSopId: item.mdmSopId,  // 안전작업 표준 일련번호_sop 작업이 아닌경우는 "tmp_001"과 같이 생성
              jobName: item.jobStepName,  // 안전작업 명
              ramRiskHazardClassCd: item.ramRiskHazardClassCd,
              relationLaw: item.relevantLegalStandards,
              ramRiskHazardId: item.mdmHazardFactorsId,
              riskHazardName: item.riskHazardName,
              riskHazardNameClass: item.ramRiskHazardClassName + ' - ' + item.riskHazardName,
              riskOccurrenceCause: item.hazardOccurReason,
              riskOccurrenceResult: item.hazardOccurResult,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })

        this.saveSop();
      }
    },
    rowClick(row, index) {
      /**
       * 이후에 해당 정보를 클릭 한 후 수정하려고 할 수 있도록 열것임
       * 다만 해당 마다 존재하는 키값이 temp로 따진 키가 아닐 경우에만 한에서 열어줄것임
       * 유해위험요인seq
       */
      this.rowIndex = index;
      this.isRowClick = true;
      this.$_.extend(this.sop, row);
      if (this.sop.editFlag !== 'C') {
        this.sop.editFlag = 'U'
        this.sop.chgUserId = this.$store.getters.user.userId
      }
      this.sopDialog = true;
    },
    addJob() {
      this.rowIndex = 0;
      this.isRowAdd = true;
      let groupMdmSopId = this.process.processCd + '/' + uid();
      let sop = { 
        ramKrasAssessScenarioId: uid(),
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
        processName: this.process.processName,
        ramTechniqueCd: 'RT00000020',
        groupMdmSopId: groupMdmSopId,
        mdmSopId: '',
        jobName: '',
        ramRiskHazardClassCd: '',
        relationLaw: '',
        ramRiskHazardId: '',
        riskHazardName: '',
        riskHazardNameClass: '',
        riskOccurrenceCause: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      this.rowClick(sop, this.rowIndex)
    },
    innerBtnClicked(col, props) {
      this.rowIndex = props.rowIndex;
      this.isRowAdd = true;
      if (col.name === 'jobName') {
        this.addRiskHazard(props)
      }
    },
    addRiskHazard(props) {
      let sop = { 
        ramKrasAssessScenarioId: uid(),
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
        processName: this.process.processName,
        ramTechniqueCd: props.row.ramTechniqueCd,
        mdmSopId: props.row.mdmSopId,
        jobName: props.row.jobName,
        ramRiskHazardClassCd: '',
        relationLaw: '',
        ramRiskHazardId: '',
        riskHazardName: '',
        riskHazardNameClass: '',
        riskOccurrenceCause: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      }
      this.rowClick(sop, this.rowIndex)
    },
    searchRiskHazard() {
      this.popupOptions.title = 'LBL0001107'; // 유해위험요인 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/base/riskHazardPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRiskHazardPopup;
    },
    closeRiskHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        // if (this.$_.findIndex(this.process.scenarios, { ramRiskHazardId: data[0].ramRiskHazardId }) === -1) {
          this.sop.ramRiskHazardId = data[0].ramRiskHazardId
          this.sop.riskHazardName = data[0].riskHazardName
          this.sop.riskHazardNameClass = data[0].ramRiskHazardClassName + ' - ' + data[0].riskHazardName
          this.sop.ramRiskHazardClassCd = data[0].ramRiskHazardClassCd
          this.sop.relationLaw = data[0].relationLaw
          this.sop.riskOccurrenceCause = ''
        // } else {
        //   window.getApp.$emit('ALERT', {
        //     title: 'LBLGUIDE', // 안내
        //     message: 'MSG0000391', // 해당 공정에 존재하는 유해위험요인입니다.
        //     type: 'warning', // success / info / warning / error
        //   });
        // }
      }
    },
    removeRiskHazard() {
      this.sop.ramRiskHazardId = null
      this.sop.riskHazardName = null
      this.sop.riskHazardNameClass = null
      this.sop.ramRiskHazardClassCd = null
      this.sop.relationLaw = null
      this.sop.riskOccurrenceCause = null
      this.sop.riskOccurrenceResult = null
    },
    searchCause() {
      this.popupOptions.title = 'SOP 검색'; // 
      this.popupOptions.param = {
        type: 'single',
        subProcessCd: this.process.processCd,
        mdmHazardFactorsId: this.sop.ramRiskHazardId,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCausePopup;
    },
    closeCausePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.sop.ramRiskHazardId = data[0].ramRiskHazardId
        this.sop.riskHazardName = data[0].riskHazardName
        this.sop.riskHazardNameClass = data[0].ramRiskHazardClassName + ' - ' + data[0].riskHazardName
        this.sop.ramRiskHazardClassCd = data[0].ramRiskHazardClassCd
        this.sop.relationLaw = data[0].relationLaw
        this.sop.riskOccurrenceCause = data[0].hazardOccurReason
        this.sop.riskOccurrenceResult = data[0].hazardOccurResult
      }
    },
    removeCause() {
      this.sop.riskOccurrenceCause = null
      this.sop.riskOccurrenceResult = null
    },
    jobChange() {
      if (this.isRowAdd) {
        if (this.$_.findIndex(this.process.scenarios, { jobName: this.sop.jobName, }) > -1) {
          this.isSameJobName = true;
        } else {
          this.isSameJobName = false;
        }
      } else {
        if (this.$_.findIndex(this.$_.reject(this.process.scenarios, {
          groupMdmSopId: this.sop.groupMdmSopId
        }), { jobName: this.sop.jobName, }) > -1) {
          this.isSameJobName = true;
        } else {
          this.isSameJobName = false;
        }
      }
    },
    accepts() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let isProgress = true;
          // if (!this.process.scenarios || this.process.scenarios.length === 0) {
          //   isProgress = false
          //   window.getApp.$emit('ALERT', {
          //     title: 'LBLGUIDE', // 안내
          //     message: 'MSGNODATA',
          //     type: 'warning', // success / info / warning / error
          //   });
          // } 
          // else if (this.isSameJobName) {
          //   window.getApp.$emit('ALERT', {
          //     title: 'LBLGUIDE', // 안내
          //     message: '동일한 작업명이 존재합니다.',
          //     type: 'warning', // success / info / warning / error
          //   });
          //   this.isSameJobName = false;
          //   return;
          // } 
          // else {
            if (isProgress) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  if (!this.sop.ramRiskHazardId) {
                    // 유해위험요인 일련번호가 없는 경우(key-in)
                    this.sop.ramRiskHazardId = uid();
                  }
                  
                  if (this.isRowAdd) {
                    this.process.scenarios.splice(this.rowIndex, 0, this.$_.clone(this.sop));
                  } else {
                    this.$_.extend(this.process.scenarios[this.rowIndex], this.$_.clone(this.sop))
                  }

                  this.isRowClick = false;
                  this.isRowAdd = false;
                  Object.assign(this.$data.sop, this.$options.data().sop);
                  this.sopDialog = false;
                  
                  this.saveSop();
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            }
          // }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    closeDialog() {
      this.isRowClick = false;
      this.isRowAdd = false;
      this.sopDialog = false;
    },
    saveSop() {
      this.$_.forEach(this.process.scenarios, sop => {
        sop.chgUserId = this.$store.getters.user.userId
      });

      this.isSave = !this.isSave
    },
    saveSopCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('saveProcess', this.process.processCd)
      this.getSops();
      /**
       * 계획정보수정
       *  변경사항을 적용하기 위함
       */
      if (this.updateBtnData.flag) {
        this.updateBtnData.research = uid();
      }
    },
    removeSop() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let message = 'MSGREMOVE'; // 삭제하시겠습니까?
        if (this.updateBtnData.flag) {
          message = '현재 평가실행 중인 유해위험요인입니다.\n관련되어 있는 정보 및 개선사항, 위험등록부가 같이 삭제됩니다.\n진행하시겠습니까?'
        }
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: message,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.scenarios = this.$_.reject(this.process.scenarios, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              /**
               * 계획정보수정
               *  변경사항을 적용하기 위함
               */
              if (this.updateBtnData.flag) {
                this.updateBtnData.research = uid();
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openPicture(props) {
      this.popupOptions.title = 'LBL0001110'; // 유해위험요인 사진
      this.popupOptions.param = {
        ramRiskHazardId: props.row.ramRiskHazardId,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazard/riskHazardPicture.vue'}`);
      this.popupOptions.width = '30%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePicturePopup;
    },
    closePicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
