var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "LBL0001108",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.process.scenarios,
                merge: _vm.grid.merge,
                columnSetting: false,
                expandAll: false,
                isFullScreen: false,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                editable:
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.isSelect &&
                  !_vm.assessTypeRoutineCheck,
                selection: "multiple",
                rowKey: "ramKrasAssessScenarioId",
              },
              on: { innerBtnClicked: _vm.innerBtnClicked },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(
                              "q-chip",
                              {
                                staticStyle: {
                                  "margin-bottom": "4px !important",
                                },
                                attrs: {
                                  color: "amber",
                                  outline: "",
                                  square: "",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() => {
                                      _vm.rowClick(props.row, props.pageIndex)
                                    }).apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$label("LBLUPDATE")) + " "
                                ),
                              ]
                            ),
                          ]
                        : col.name === "picture"
                        ? [
                            _c("q-btn", {
                              staticClass: "tableinnerBtn",
                              attrs: {
                                flat: "",
                                align: "center",
                                color: "blue-6",
                                label: "",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.openPicture(props, col)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        props.row.editFlag !== "C"
                                          ? [
                                              _c("q-btn", {
                                                attrs: {
                                                  unelevated: "",
                                                  round: "",
                                                  dense: "",
                                                  size: "10px",
                                                  color: "primary",
                                                  icon: "wallpaper",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-chip",
                    {
                      staticClass: "q-ml-sm",
                      staticStyle: {
                        height: "26px",
                        "margin-right": "2px !important",
                      },
                      attrs: {
                        outline: "",
                        square: "",
                        clickable: "",
                        size: "md",
                        color: "light-green-8",
                        "text-color": "white",
                        icon: "contact_support",
                        label: "범례",
                      },
                    },
                    [
                      _c(
                        "q-popup-proxy",
                        [
                          _c(
                            "q-banner",
                            [
                              _c("q-chip", {
                                attrs: {
                                  color: "teal",
                                  outline: "",
                                  square: "",
                                },
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$label("LBL0001099")) + " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.isSelect &&
                              !_vm.assessTypeRoutineCheck,
                            expression:
                              "editable&&!disabled&&isSelect&&!assessTypeRoutineCheck",
                          },
                        ],
                        attrs: { label: "LBLADDDIRECTLY", icon: "add" },
                        on: { btnClicked: _vm.addJob },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.isSelect &&
                              !_vm.assessTypeRoutineCheck,
                            expression:
                              "editable&&!disabled&&isSelect&&!assessTypeRoutineCheck",
                          },
                        ],
                        attrs: { label: "LBL0001100", icon: "add" },
                        on: { btnClicked: _vm.openSop },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable &&
                              !_vm.disabled &&
                              _vm.isSelect &&
                              !_vm.assessTypeRoutineCheck,
                            expression:
                              "editable&&!disabled&&isSelect&&!assessTypeRoutineCheck",
                          },
                        ],
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeSop },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.process.scenarios,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveSop,
                          btnCallback: _vm.saveSopCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "q-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.sopDialog,
            callback: function ($$v) {
              _vm.sopDialog = $$v
            },
            expression: "sopDialog",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { "min-width": "350px" } },
            [
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "LBL0001099" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.editable &&
                                      !_vm.disabled &&
                                      _vm.isSelect &&
                                      _vm.isRowEditing &&
                                      !_vm.assessTypeRoutineCheck,
                                    expression:
                                      "editable&&!disabled&&isSelect&&isRowEditing&&!assessTypeRoutineCheck",
                                  },
                                ],
                                attrs: {
                                  disabled: _vm.disabledSop,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: { btnClicked: _vm.accepts },
                              }),
                              _c("c-btn", {
                                attrs: { label: "LBLCLOSE", icon: "cancel" },
                                on: { btnClicked: _vm.closeDialog },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: true,
                                label: "LBLPROCESS",
                                name: "processName",
                              },
                              model: {
                                value: _vm.sop.processName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sop, "processName", $$v)
                                },
                                expression: "sop.processName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled:
                                  _vm.disabledSop ||
                                  !_vm.isRowClick ||
                                  Boolean(_vm.sop.mdmSopId) ||
                                  _vm.assessTypeRoutineCheck,
                                required: true,
                                label: "LBLJOB",
                                name: "jobName",
                              },
                              on: { dataChange: _vm.jobChange },
                              model: {
                                value: _vm.sop.jobName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sop, "jobName", $$v)
                                },
                                expression: "sop.jobName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                afterIcon:
                                  _vm.editable &&
                                  !_vm.disabledSop &&
                                  _vm.isRowEditing &&
                                  !_vm.assessTypeRoutineCheck
                                    ? [
                                        {
                                          name: "search",
                                          click: true,
                                          callbackName: "searchRiskHazard",
                                          color: "teal",
                                        },
                                        {
                                          name: "close",
                                          click: true,
                                          callbackName: "removeRiskHazard",
                                          color: "red",
                                        },
                                      ]
                                    : null,
                                editable: _vm.editable,
                                disabled:
                                  _vm.disabledSop || _vm.assessTypeRoutineCheck,
                                required: true,
                                close: false,
                                label: "LBL0001050",
                                name: "riskHazardName",
                              },
                              on: {
                                searchRiskHazard: _vm.searchRiskHazard,
                                removeRiskHazard: _vm.removeRiskHazard,
                                dataChange: _vm.chagneRiskHazard,
                              },
                              model: {
                                value: _vm.sop.riskHazardName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sop, "riskHazardName", $$v)
                                },
                                expression: "sop.riskHazardName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled:
                                  _vm.disabledSop || _vm.assessTypeRoutineCheck,
                                afterIcon:
                                  _vm.editable &&
                                  !_vm.disabledSop &&
                                  _vm.isRowEditing &&
                                  !_vm.assessTypeRoutineCheck
                                    ? [
                                        {
                                          name: "search",
                                          click: true,
                                          callbackName: "searchCause",
                                          color: "teal",
                                        },
                                        {
                                          name: "close",
                                          click: true,
                                          callbackName: "removeCause",
                                          color: "red",
                                        },
                                      ]
                                    : null,
                                close: false,
                                label: "상황/원인",
                                name: "riskOccurrenceCause",
                              },
                              on: {
                                searchCause: _vm.searchCause,
                                removeCause: _vm.removeCause,
                              },
                              model: {
                                value: _vm.sop.riskOccurrenceCause,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sop, "riskOccurrenceCause", $$v)
                                },
                                expression: "sop.riskOccurrenceCause",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }